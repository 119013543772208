.mainHeader {
  padding: 10px;
  background-color: #f7f8fa;
  border-bottom: 1px solid #4444;

  @media (max-width: 767px) {
    background-color: #f7f8fa;
    padding: 7px;
  }
}

.mainSlider {
  background-color: #f7f8fa;
  border-right: 1px solid #4444;

  @media (max-width: 767px) {
    background-color: #f7f8fa;
    position: absolute;
    z-index: 10;
    border-radius: 0px 0px 10px 0px;
  }
}

.vehicleSlider {
  background-color: #f7f8fa;

  @media (max-width: 767px) {
    background-color: #f7f8fa;
    position: absolute;
    right: 0;
    border-radius: 0px 0px 0px 10px;
  }
}
.vehicleHideSlider {
  display: none;
}

.mainTable {
  width: 100%;
  background-color: #f7f8fa;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 767px) {
    background-color: #f7f8fa;
    /* height: 100vh; */
  }
}
