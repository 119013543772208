.labels {
  font-weight: 800 !important;
  margin-right: 1px;

  @media (max-width: 767px) {
    font-weight: 600 !important;
  }
}

.textLines {
  display: flex !important;
  justify-content: space-between !important;
}

.textLines p {
  font-size: 11pt;
}

.editButton {
  position: absolute !important;
  top: -40px !important;
  right: -36px !important;
  margin: 5px !important;
  display: flex !important;
  justify-content: end !important;
  background-color: #ff6633 !important;
  color: #fff !important;
  border-radius: 7px !important;
  z-index: 100 !important;
}

.gridItem {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.divider {
  margin: 10px !important;
}

.textField {
  width: 100% !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.highlightedLabels {
  font-weight: 800 !important;
  margin-right: 1px;
  background-color: #ffff00;
  padding: 0px 10px;

  @media (max-width: 767px) {
    font-weight: 600 !important;
  }
}
