.containerSearch {
  background-color: #e9e9e9 !important;
  padding: 0px 0px 0px 10px !important;
  margin-right: 10px !important;
  border-radius: 5px !important;
  @media (max-width: 767px) {
    background-color: #e9e9e9 !important;
    margin-bottom:5px !important;
    border-radius: 5px !important;
    width: 100% !important;
    padding:7px !important;
    padding-left: 20px !important;

  }


}


