.containerTable {
  background-color: #ffe433 !important;
  padding: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 10px !important;
  color: #f33 !important;
  align-content: center !important;
  align-self: center !important;
  vertical-align: middle !important;
  margin-bottom: 20px !important;
  max-width: 170px !important;
  @media (max-width: 767px) {
    display: flex !important;
    flex-direction: row !important;
    position: fixed !important;
    bottom: 50px !important;
    left:10px !important;
    max-width: 100% !important;
    align-items: center;
   

  }
}
