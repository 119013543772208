body {
  overflow-y: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff70;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100500;
  align-items: center;
  justify-content: center;
}

.loadingDisabled {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.genericModal .MuiButton-contained.MuiButton-containedPrimary,
div[role="dialog"] .MuiButton-contained.MuiButton-containedPrimary {
  background-color: #ff6633 !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
}

.confirmModal .MuiButton-sizeMedium {
  background-color: #f34 !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.Mui-disabled {
  background-color: #0000001f !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
}

.MuiDialogContent-root {
  overflow-y: auto !important;
}
.Mui-completed path {
  color: #64b357 !important;
}
#allContentWrapper {
  display: flex;
  height: calc(100vh - 61px);
}

#contentWrapper {
  width: 100%;
  overflow-y: auto;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2) {
  body {
    overflow-y: scroll;
  }
  #headerWrapper {
    zoom: 80%;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 98%;
  }

  #contentWrapper {
    margin-top: 60px;
    margin-bottom: 10px !important;
  }
  #sliderWrapper {
    position: inherit !important;
    margin-top: 44px !important;
    left: 0px;
    z-index: 1000 !important;
    @media screen and (orientation: portrait) {
      position: fixed !important;
      overflow: scroll !important;
      height: 80vh !important;
      zoom: 110%;
      overflow: scroll !important;
      min-width: 100vw !important;
    }
  }

  /* #addNew_Button{
    position:fixed !important;
    top:150px !important;
    margin-bottom: -50px !important;
    } */

  #allContentWrapper {
    margin-bottom: 10px !important;

    height: auto !important;
  }
  #addNew_Button {
    top: 33px !important;
  }

  #searchWrapper {
    height: calc(125vh - 60px);
  }
  td:last-child {
    width: 100% !important;
    display: flex !important;
    /* justify-content: space-between !important; */
    border-bottom: 50px !important;
    align-items: center;
    @media screen and (orientation: portrait) {
      background-color: #ededed;
    }
  }
  tfoot td:last-child {
    @media (max-width: 767px) {
      width: 1400% !important;
    }
  }
  .Toastify__close-button > svg {
    fill: currentColor;
    height: 50px !important;
    width: 50px !important;
    display: flex;
  }
}
.manageOtherUsers {
  font-size: "1rem !important";
}
