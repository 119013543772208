.textField {
  width: 100% !important;
  margin-top: 15px !important;
}

.checkbox {
  position: absolute !important;
  left: -7px !important;
  top: -7px !important;
  color: #fff !important;
  z-index: 5000 !important;
  scale: 0.7;
  background-color: #ff6633 !important;
  border-radius: 0px 0px 7px 0px !important;
}

.dropzoneArea {
  max-height: 70vh !important;
  overflow-y: scroll !important;

  width: 100% !important;
}

.textValidator {
  margin: 7px !important;
  margin-right: 7px !important;
  @media (max-width: 767px) {
    margin: 1px !important;
    margin-top: 10px !important;
  }
}

.imageContainer {
  display: grid;
  width: 100%;
  cursor: pointer;
  gap: 15px;
  overflow: auto;
  @media (max-width: 767px) {
    margin-top: 30px;

    overflow: scroll;
  }
}

.buttonsContainer {
  display: grid;
  position: fixed;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  gap: 10px;
  overflow: hidden !important;
  margin-top: -60px;
  margin-left: -5px;
  padding: 5px;
  background-color: #fff;
  z-index: 100;

  @media (max-width: 767px) {
    width: 95%;
    margin-top: -75px;
    grid-template-columns: repeat(4, 1fr);
    font-size: 7;
  }
}
.buttons {
  color: "white";
  text-decoration: "none";
  margin: 5px;
  @media (max-width: 767px) {
  }
}

.buttonText {
  @media (max-width: 767px) {
    font-size: 11px !important;
  }
}

.image {
  z-index: 10;
  cursor: grab;
  padding: 0px;
  position: relative;
  max-height: 100%;
  padding-bottom: 13vh;
  overflow: hidden;
  border-radius: 5px;
  @media (max-width: 767px) {
    padding-bottom: 100%;
  }
}

.img {
  width: 100%;
  z-index: 100;
  cursor: pointer;
  object-fit: cover;
  left: 0;
  position: absolute;
  top: 0;
  overflow: hidden;
}
canvas {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  overflow: hidden !important;
  z-index: 100;
  margin: auto;
}
.gridItemContent {
  padding: 5px;
  overflow: hidden;
  position: relative;
}

.dropzone {
  display: grid;
  flex: 1;
  width: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  /* overflow: hidden !important; */
  height: 82vh;
  /* background-color: aqua; */
  @media (max-width: 767px) {
    width: 100% !important;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2) {
  }
}
