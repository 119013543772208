.dialogTitle {
  align-items: center;
  justify-content: center;
  display: flex;
  @media (max-width: 767px) {
    margin: 5px;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    width: 80% !important;
  }
}
.notePaper {
  position: absolute !important;
  left: -250px;
  width: 200px !important;
  height: 200px !important;
  top: 10px;
  background-color: #ff9 !important;
  --paper-dark: #e5c93d;
  --paper-color: #ffed87;

  background: linear-gradient(
    135deg,
    var(--paper-dark),
    30%,
    var(--paper-color)
  );
  box-shadow: 3px 3px 2px var(--paper-shadow);

  transform-origin: top left;
  padding: 10px;
  @media (max-width: 767px) {
    position: absolute;
    left: 0px;
  }
}

.title {
  margin: 10px;
  font-size: 26px;
  max-width: 100%;
  word-break: break-word;
  @media (max-width: 767px) {
    margin: 5px;
    font-size: 18px;
    font-weight: 600;
  }
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.notes {
  position: absolute;
  left: 20px;
  top: 20px;
}

.closeIcon {
  cursor: pointer;
  font-size: 28px;
}

.closeIconForNotes {
  float: right !important;
  cursor: pointer;
  font-size: 18px !important;
  margin-right: -2px;
  margin-bottom: 5px;
}
