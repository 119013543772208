.dividedContainers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  @media (max-width: 767px) {
    display: block;
  }
}
.mainContainer {
  border-radius: 10px;
  margin: 10px 0px;
  padding: 6px 18px;
  background-color: rgb(255, 255, 255);
  overflow-y: auto;
  @media (max-width: 767px) {
    padding: 0 18px 18px 18px;
  }
}
/* .mainContainer > :nth-child(2) {
  border-left: 2px solid black; 
} */
.headingLabel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 0 1em 0;
  @media (max-width: 767px) {
    margin-top: 1em;
    grid-template-columns: 1fr;
  }
}
.headingLabel p {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 0.02em;
  color: rgb(77, 94, 128);
}
fieldset {
  border-radius: 10px;
}
legend {
  padding: 0 5px;
}
.stepsContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
