.option {
  padding: 12px;
  padding-bottom: 12px;
  display: flex;
  color: #4d5e80;
  width: 100%;

  border-radius: 10px;
}

.link {
  display: flex;
  text-decoration: none;
}

.disableLink {
  display: flex;
  text-decoration: none;
  cursor: not-allowed;
  opacity: 0.4;
  pointer-events: none;
}

.optionsSelected {
  padding: 12px;
  padding-bottom: 12px;
  display: flex;
  background-color: #fff;
  color: #ff6633;
  width: 100%;
  border-radius: 10px;
}

.icon {
  margin-right: 15px;
}
.containerOptions {
  display: flex !important;
  flex-direction: column !important;
  width: 200px !important;
  margin-top: -10px !important;
  background-color: #f7f8fa !important;
  padding: 15px !important;
  @media (max-width: 767px) {
    display: flex !important;
    position: absolute !important;
    top: -10px !important;
    background-color: #f7f8fa !important;
    flex-direction: column !important;
    z-index: 300 !important;
    border-radius: 0px 0px 10px 0px !important;
    width: 200px !important;
    padding: 25px !important;
    z-index: 400 !;
    height: 90vh;
  }
}

.containerOptionsHide {
  display: none !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 200;
  cursor: pointer;
}
.overlayHide {
  display: none;
}

.closeButton {
  background-color: #fff !important;
  position: fixed !important;
  top: 1.5vh !important;
  z-index: 500 !important;
  left: 12vw !important;
  @media (max-width: 767px) {
    background-color: #fff !important;
    position: fixed !important;
    top: 2vw !important;
    z-index: 500 !important;
    left: 11vw !important;
  }
}
