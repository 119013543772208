.App {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;
}

.App-Column {
  flex-direction: column;
}

.loginContainer {
  width: 400px;
  height: 250px !important;
  margin-top: 200px;
}
.loginFields {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px !important;
}
.textfield {
  margin-bottom: 20px !important;
  width: 100% !important;
}

.form {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

p {
  margin-top: 0;
}

input {
  margin: 15px 0;
  padding: 10px;
}

.submit {
  padding: 10px;
  margin-bottom: 15px;
  color: #fff !important;
}

.error {
  color: red;
  font-weight: bold;
}

.useContext {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 20px auto 500px;
}

.todoContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.todoItem {
  border: 1px solid #eee;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.todoItem p {
  margin: 0;
}

.errorText {
  font-size: 17px !important;
  color: red !important;
}
