.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
  text-align: right;
  /* media queries */
  @media (max-width: 767px) {
    background-color: #ff2f;
  }
  @media (max-width: 400px) {
    background-color: #f4ff;
  }
}

.bodyContainer {
  padding: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
}

.headerContainer {
  background-color: #ff6633;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}
.cancelIcon {
  padding: 5px;
}
.content {
  text-align: center !important;
  font-size: 20px !important;
}
.modalContent {
  @media (min-width: 600px) {
    display: flex;
    justify-content: center;
  }
}
.boldParagraph {
  font-weight: 600;
}
.paragraph {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
