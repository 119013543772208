.paper {
  width: 96%;
  padding: 2%;
}
.divider {
  margin: 10px 0 !important;
}
.heading,
.userName {
  font-size: 1rem !important;
  font-weight: 600 !important;
  display: flex;
}
.userName {
  margin-bottom: 10px !important;
}
.heading {
  justify-content: center;
}
.tableRow {
  max-width: 100%;
}
.tableRow:last-child td,
.tableRow:last-child th {
  border: 0;
}
.autocomplete {
  max-width: 100%;
}
.icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 20px;
}
.center {
  display: flex;
  justify-content: center;
  gap: 3rem;
}
.tableContainer {
  margin-top: 2rem;
}
.downloadButton {
  color: white !important;
  margin-top: 2.7rem;
  display: flex;
  justify-content: center;
}
.gridItem {
  display: grid;
  place-items: center;
}
.tableCell {
  font-weight: bold !important;
}
.footer {
  width: 1400% !important;
}
