.textField {
    margin: 13px !important;
    width: 100%;
}

.checkbox {
    position: absolute !important;
    left: -10px !important;
    top: -10px !important;
    color: #ff6633 !important;
}

.dropzoneArea {
    margin: 25px 15px 15px 18px;
    max-height: 120px !important;
    overflow-y: auto;
    width: 95% !important;
    @media (max-width: 767px) {
        margin: 5px 0px 5px 7px;
    max-height: 60px !important;
    overflow-y: auto;
    width: 95% !important;
      }
}