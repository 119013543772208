.statisticallyContainer {
  display: flex;
  justify-content: space-evenly;
}

.statisticallyText {
  padding: 15px;
  font-size: 22px;
  text-align: center;
  margin: 10px 0px;
}

.statisticallyText span {
  font-weight: 100;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 9pt;
  display: block;
}

.profitMetrics,
.profitSide,
.lossSide {
  display: flex;
  flex-direction: row;
  margin: 0;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    padding: 0.5em !important;
    border-radius: 10px !important;
    gap: 0.5rem !important;
    width: 100% !important;
    margin-right: 8px !important;
  }
}

.profitSide {
  background-color: #aacbb8a1;
  padding: 0.5em 3em;
  width: 50%;
  border-radius: 10px 0 0 0;
  gap: 2em;
}

.lossSide {
  background-color: #f98e70d6;
  padding: 0.5em 3em;
  width: 50%;
  border-radius: 0 10px 0 0;
  display: flex;
  justify-content: flex-end;
  gap: 2em;
}

.profitMetrics {
  align-items: center;
}

.statisticallySubtitle {
  font-weight: 300;
  font-size: 14px;
  display: block;
  padding: 0;
  margin: 0;
}

.statisticallySubtitle div {
  display: inline-block;
  font-weight: 400;
 
  padding: 0;
  margin: 0;
}
.statisticallyFieldset {
  margin-top: 50px;
  border-radius: 10px;
  margin: 10px;
  border-color: #eeeeee;
  /* background-color: #f5f5f561; */
  border-style: solid;
  color: #444;
}

.saveAsImage {
  display: flex !important;
  justify-content: center !important;
  /* float: right;
  margin-right: 10px !important;
  margin-top: -65px !important; */
  @media (max-width: 767px) {
    width: 100% !important;
    float: inherit;
    margin: 10px;
  }
}
.reportsBarLabel {
  @media (max-width: 768px) {
    display: none;
  }
}
