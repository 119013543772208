.fieldset {
  margin: 10px;
  width: 100%;
  color: #444 !important;
}

.mainContainer {
  height: 100% !important;
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  margin-top: 20 !important;
}

.container {
  display: flex;
  padding: 0px !important;
  justify-content: space-between;
  @media (max-width: 767px) {
    padding: 5px !important;
    margin-left: -10px;
  }
}

.containerModal {
  min-width: 600px;
  @media (max-width: 767px) {
    min-width: auto;
  }
}

.closeIcon {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
}

.tabHeader {
  border-top-left-radius: 4px 4px !important;
  border-top-right-radius: 4px 4px !important;
  height: 43px !important;
  background-color: #f63 !important;
}
.tabHeaderDisabled {
  border-top-left-radius: 4px 4px !important;
  border-top-right-radius: 4px 4px !important;
  height: 43px !important;
  background-color: #e0e0e0 !important;
}
.tabHeaderCompleted {
  border-top-left-radius: 4px 4px !important;
  border-top-right-radius: 4px 4px !important;
  height: 43px !important;
  background-color: #64b357 !important;
}

.title {
  color: white !important;
  padding-top: 15px !important;
  padding-left: 15px !important;
  font-size: 12px !important;
  font-weight: 600;
  @media (max-width: 767px) {
    min-width: 100px !important;
  }
}

.smallIcons {
  padding: 10px;
  color: #fff !important;
}

.buttons {
  color: #fff !important;
  margin-top: 3px !important;
  margin: 10px !important;
}

.fileContainer {
  display: flex !important;
  width: auto !important;
  justify-content: space-between !important;
  vertical-align: middle !important;
}

.stepper {
  margin: 10px !important;
}

.stepperLabel {
  font-weight: 800 !important;
  font-size: 15px !important;
}

.divider {
  margin-bottom: -10px !important;
}

.informationContainer {
  margin: 10px !important;
}

.gridFileButton {
  text-align: end !important;
  @media (max-width: 767px) {
    text-align: center !important;
  }
}

.gridFileText {
  text-align: start !important;
  @media (max-width: 767px) {
    text-align: center !important;
  }
}

.deleteIcon {
  background-color: #ff6633 !important;
  color: #fff !important;
  padding: 5px !important;

  border-radius: 7px !important;
  cursor: pointer;
  @media (max-width: 767px) {
    text-align: center !important;
    margin-left: -5px !important;
  }
}

.stepContainer {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  background-color: #f6f6f6 !important;
  border-radius: 10px !important;
  margin-left: 2px !important;
  @media (max-width: 767px) {
    display: flex !important;
    flex-direction: column !important;
  }
}

.reserveBox {

  gap: 2 !important;
  @media (max-width: 767px) {
    display: flex !important;
    flex-direction: column !important;
    gap: 3 !important;
    text-align: center !important;
  }
}
.reserveBoxObs {
  display: flex !important;
  flex-direction: row !important;

  @media (max-width: 767px) {
    display: flex !important;
    flex-direction: column !important;
  }
}

.step3_2 {
  display: flex !important;
  justify-content: space-between !important;
  @media (max-width: 767px) {
    display: flex !important;
    flex-direction: column !important;
    gap: 3 !important;
    text-align: center !important;
  }
}

.autocomplete3_2 {
  width: 40% !important;
  @media (max-width: 767px) {
    width: auto !important;
    margin-bottom: 10px !important;
  }
}

.listElement {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  background-color: #f6f6f6 !important;
  border-radius: 10px !important;
  margin-left: 2px !important;
  margin: 10px !important;
  padding: 10px !important;
  @media (max-width: 767px) {
    display: flex !important;
    align-items: center !important;
    flex-direction: column-reverse;
  }
}

.container4_1Completed {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  background-color: #dbf3e1 !important ;

  border-radius: 10px !important;
  margin-left: 2px !important;
  margin: 10px !important;
  padding: 10px !important;
  @media (max-width: 767px) {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    text-align: center !important;
  }
}

.container4_1 {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  background-color: #f6f6f6 !important ;

  border-radius: 10px !important;
  margin-left: 2px !important;
  margin: 10px !important;
  padding: 10px !important;
  @media (max-width: 767px) {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    text-align: center !important;
  }
}

.datePTF {
  width: 320 !important;
  margin-bottom: 15 !important;
}

.modifyBy {
  font-style: italic !important;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 8px !important;
    display: flex !important;
  }
}
.modifyByContainer {
  margin-top: 11px;
  display: flex !important;
  max-height: 20px;
  @media (max-width: 767px) {
    margin-top: 5px;
    display: flex !important;
    flex-direction: column !important;
  }
}

.simpleWrapperTitle {
  color: white !important;
  padding-top: 10px !important;
  padding-left: 15px !important;
  font-size: 16px !important;
  font-weight: 600;
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    min-width: 100px !important;
  }
}
.simpleHeader {
  /* background-color: rgba(0, 0, 0, 0.38); */
  background-color: #97abb8;
  height: 43px !important;
}
