.nonSelected {
  background-color: #fff !important;
  color: #4d5e80 !important;
  margin-top: 10px !important;
  width: 30px !important;
  height: 30px !important;
  scale: 0.8;

  @media (max-width: 767px) {
    background-color: #fff;
    color: #4d5e80 !important;
    margin-top: 10px !important;
  }
}

.selected {
  box-shadow: #ff6633 0px 3px;
  color: #ff6633 !important;
  width: 30px !important;
  height: 30px !important;
  margin-top: 10px !important;

  @media (max-width: 767px) {
    background-color: #fff !important;
    color: #ff6633 !important;
    margin-top: 10px !important;
  }
}
.selectedTextMenu {
  color: #ff6633 !important;
  font-size: 14px !important;
  margin-top: 15px !important;
  margin-left: 20px !important;
  line-height: 17px !important;
}

.nonSelectedTextMenu {
  color: #000 !important;
  margin-top: 15px !important;
  font-size: 14px !important;
  margin-left: 20px !important;
  line-height: 17px !important;
}

.selectedText {
  color: #ff6633 !important;
  margin-top: 16px !important;
  margin-left: 20px !important;
  line-height: 17px !important;
}
.selectedTextSubMenu {
  display: none;
}

.nonSelectedText {
  color: #000 !important;
  margin-top: 16px !important;
  margin-left: 20px !important;
  line-height: 17px !important;
}
.nonSelectedTextSubMenu {

  text-decoration: none;
  padding-left:13px;
  padding-left:13px;
  padding-top:10px;
  padding-bottom:10px;
  margin:-7px;
  width: 100%;
  color: #000 !important;
  background-color: #4442;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 7px 15px;
  margin-bottom: 2px;
  
}

.container {
  display: flex !important;
  padding: 10px !important;
  justify-content: space-between !important;
  overflow: hidden !important;
  position: relative !important;
  max-width: 40px !important;
  transition-timing-function: ease-in-out !important;
  transition: 0.95s !important;
  margin-bottom: 120px;

  /* Move into place */
  transform: translateY(0) !important;
  @media (max-width: 900px) {
    display: flex !important;
    padding: 10px !important;
    justify-content: space-between !important;
    overflow: scroll !important;
    position: relative !important;
    min-width:100vw !important;
    transition-timing-function: ease-in-out !important;
    transition: 0.5s !important;

    /* Move into place */
    transform: translateY(0) !important;
  }
}

.container:hover {
  display: flex !important;
  padding: 10px !important;
  justify-content: space-between !important;
  overflow: hidden !important;
  position: relative !important;
  max-width: 300px !important;
  transition-timing-function: ease-in-out !important;

  transition: 0.5s !important;

  /* Move into place */
  transform: translateY(0) !important;
}
