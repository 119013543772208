.container {
  display: flex;
  padding: 5;
  justify-content: space-between;
  background-color: "#ff5";
  height: 40px;

  @media (max-width: 767px) {
    display: flex;
    padding: 5;
    justify-content: space-between;
    background-color: "#ff5";
  }
}
.fileName{
  white-space: nowrap; 
  padding: 0 10px;
  width: 250px; 
  overflow: hidden;
  text-overflow: ellipsis; 
  direction: rtl;
}

.title {
  padding-top: 10px !important;
  padding-left: 15px !important;
  font-size: large;
  font-weight: 600 !important;

  @media (max-width: 767px) {
    display: none;
  }
}

.fab {
  background-color: #f7f8fa !important;
  margin-right: 10px !important;
  color: #4d5e80 !important;
  cursor: "pointer" !important;
}

.badge {
  background-color: #4e4e4e !important;
  color: #ffffff !important;
  font-size: 12pt !important;
}

.messageRead {
  color: #4d5e80 !important;
  font-weight: lighter !important;
}

.messageNotRead {
  color: #4e4e4e !important;
  font-weight: bold !important;
}

.notificationRead {
  background-color: #ffffff;
}

.notificationNotRead {
  background-color: #e9e9e9;
}

.username {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 767px) {
    display: none;
  }
}

.searchField {
  display: flex;
  background-color: #fff;
  border-radius: 50px;
  height: 35px;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 767px) {
    display: flex;
    border-radius: 50px;
    height: 35px;
    margin-left: 45px;
    margin-right: 20px;
    margin-top: 3px;
    background-color: #fff;
  }
}

.mainHeader {
  padding: 10px;
  background-color: #f7f8fa;
  border-bottom: 1px solid #4444;

  @media (max-width: 767px) {
    background-color: #f7f8fa;
    padding: 7px;
  }
}

.mainSlider::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }

.mainSlider {
  display: flex !important;
  position: inherit !important;
  background-color: #f7f8fa;
  border-right: 1px solid #4444;
  overflow: auto !important;


  @media (max-width: 767px) {
    background-color: #f7f8fa;
    position: absolute;
    z-index: 400;
    border-radius: 0px 0px 10px 0px;
    overflow: hidden !important;
    width: 600px !important;
  }
}

.mainSliderHide {
  transition-timing-function: ease-in-out !important;
  transition: 0.95s !important;
  display: none;
}

.vehicleSlider {
  background-color: #f7f8fa;

  @media (max-width: 767px) {
    background-color: #f7f8fa;
    position: absolute;
    right: 0;
    border-radius: 0px 0px 0px 10px;
  }
}

.vehicleHideSlider {
  display: none;
}

.mainTable {
  width: 100%;
  background-color: #f7f8fa;

  @media (max-width: 767px) {
    background-color: #f7f8fa;
    /* height: 100vh; */
  }
}

.wrapper {
  position: fixed;
  width: 100%;
  left: 0;
  height: 100vh;
  margin-top: 50px;
  background-color: #00000077;
  z-index: 1000;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.searchResults {
  margin-top: 100px;
  width: 600px;
  min-height: 70%;
  max-height: 70%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  & a {
    text-decoration: none !important;
    color: #000;
  }
  & a:hover {
    background: #f7f7f7;
  }
}

.linkFull {
  width: 100%;
  height: 100%;
  display: block;
  padding-left: 10px;
}

.searchWrapper {
  padding: 0 30px;
}

.iconWrapper {
  background-color: #f7f7f7;
  width: 100%;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  border: 3px solid #fff;
  box-shadow: #eee 0px 1px 1px, #ccc 0px 0px 0px 1px;
}

.searchListItem {
  border-bottom: 1px solid #eee;
  margin-top: 5px;
  padding-bottom: 15px;
}

